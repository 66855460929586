<template>
  <div class="index">
    <div data-server-rendered="true" id="__nuxt">
      <!---->
      <div id="__layout">
        <div class="full-wrap">
          <headerCom :isactive="isactive" :aboutUs="true"></headerCom>
          <div class="n-content" data-v-5bbd21ad>
           
            <div class="n-c-content" data-v-5bbd21ad>
              <div class="n-c-wrap" data-v-5bbd21ad>
                <div class="n-c-item" data-v-5bbd21ad>
                  <div class="n-c-iteml" data-v-5bbd21ad>
                    <h1 data-v-5bbd21ad>招工旺季即将来临，提前布局很重要</h1>
                    <p class="default-desc" data-v-5bbd21ad>
                      招工旺季即将来临，提前布局很重要
                    </p>
                    <div class="mobile-content" data-v-5bbd21ad>
                      <p data-v-5bbd21ad>招工旺季即将来临，提前布局很重要</p>
                      <img
                        src="../..//assets/picture/1629253068794_85649.png"
                        alt
                        data-v-5bbd21ad
                      />
                    </div>
                    <div class="news-time" data-v-5bbd21ad>2021-07-30</div>
                  </div>
                  <div class="n-c-itemr" data-v-5bbd21ad>
                    <a
                      href="/news/detail/56"
                      style="display: inline-block"
                      data-v-5bbd21ad
                      ><img
                        src="../..//assets/picture/1629253068794_85649.png"
                        alt=""
                        data-v-5bbd21ad
                    /></a>
                  </div>
                </div>
                <div class="n-c-item" data-v-5bbd21ad>
                  <div class="n-c-iteml" data-v-5bbd21ad>
                    <h1 data-v-5bbd21ad>
                      2021中国蓝领数字化创新大会报名正式开启！！
                    </h1>
                    <p class="default-desc" data-v-5bbd21ad>
                      2021中国蓝领数字化创新大会
                    </p>
                    <div class="mobile-content" data-v-5bbd21ad>
                      <p data-v-5bbd21ad>2021中国蓝领数字化创新大会</p>
                      <img
                        src="../..//assets/picture/1625454236378_47787.png"
                        alt
                        data-v-5bbd21ad
                      />
                    </div>
                    <div class="news-time" data-v-5bbd21ad>2021-07-02</div>
                  </div>
                  <div class="n-c-itemr" data-v-5bbd21ad>
                    <a
                      href="/news/detail/55"
                      style="display: inline-block"
                      data-v-5bbd21ad
                      ><img
                        src="../..//assets/picture/1625454236378_47787.png"
                        alt=""
                        data-v-5bbd21ad
                    /></a>
                  </div>
                </div>
                <div class="n-c-item" data-v-5bbd21ad>
                  <div class="n-c-iteml" data-v-5bbd21ad>
                    <h1 data-v-5bbd21ad>618大促，物流行业挣钱吗？</h1>
                    <p class="default-desc" data-v-5bbd21ad>
                      临近618的用人量更会达到顶点，有的站点一天临时工上人量就高达600-700人之多，做物流板块真的能赚钱吗？中间有哪些隐藏的风险？听沁薪科技一一道来。
                    </p>
                    <div class="mobile-content" data-v-5bbd21ad>
                      <p data-v-5bbd21ad>
                        临近618的用人量更会达到顶点，有的站点一天临时工上人量就高达600-700人之多，做物流板块真的能赚钱吗？中间有哪些隐藏的风险？听沁薪科技一一道来。
                      </p>
                      <img
                        src="../..//assets/picture/1624256871163_85773.png"
                        alt
                        data-v-5bbd21ad
                      />
                    </div>
                    <div class="news-time" data-v-5bbd21ad>2021-06-11</div>
                  </div>
                  <div class="n-c-itemr" data-v-5bbd21ad>
                    <a
                      href="/news/detail/54"
                      style="display: inline-block"
                      data-v-5bbd21ad
                      ><img
                        src="../..//assets/picture/1624256871163_85773.png"
                        alt=""
                        data-v-5bbd21ad
                    /></a>
                  </div>
                </div>
                <div class="n-c-item" data-v-5bbd21ad>
                  <div class="n-c-iteml" data-v-5bbd21ad>
                    <h1 data-v-5bbd21ad>
                      日结用工必看：私对私转账有何风险？怎么开票？
                    </h1>
                    <p class="default-desc" data-v-5bbd21ad>
                      很多没有尝试过日结用工的劳务公司，对于其中的法律和财税问题有诸多不解。在此，沁薪科技特为大家解答其中最典型的两个问题。
                    </p>
                    <div class="mobile-content" data-v-5bbd21ad>
                      <p data-v-5bbd21ad>
                        很多没有尝试过日结用工的劳务公司，对于其中的法律和财税问题有诸多不解。在此，沁薪科技特为大家解答其中最典型的两个问题。
                      </p>
                      <img
                        src="../..//assets/picture/1624256251700_20734.jpg"
                        alt
                        data-v-5bbd21ad
                      />
                    </div>
                    <div class="news-time" data-v-5bbd21ad>2021-06-04</div>
                  </div>
                  <div class="n-c-itemr" data-v-5bbd21ad>
                    <a
                      href="/news/detail/53"
                      style="display: inline-block"
                      data-v-5bbd21ad
                      ><img
                        src="../..//assets/picture/1624256251700_20734.jpg"
                        alt=""
                        data-v-5bbd21ad
                    /></a>
                  </div>
                </div>
                <div class="n-c-item" data-v-5bbd21ad>
                  <div class="n-c-iteml" data-v-5bbd21ad>
                    <h1 data-v-5bbd21ad>今年暑假工行情怎么样？</h1>
                    <p class="default-desc" data-v-5bbd21ad>
                      5月，劳务圈紧锣密鼓的开展暑假工业务。到了6月，就是对接项目最忙的时候，今年暑假工到底是什么行情呢？
                    </p>
                    <div class="mobile-content" data-v-5bbd21ad>
                      <p data-v-5bbd21ad>
                        5月，劳务圈紧锣密鼓的开展暑假工业务。到了6月，就是对接项目最忙的时候，今年暑假工到底是什么行情呢？
                      </p>
                      <img
                        src="../..//assets/picture/1624257817216_51529.png"
                        alt
                        data-v-5bbd21ad
                      />
                    </div>
                    <div class="news-time" data-v-5bbd21ad>2021-05-28</div>
                  </div>
                  <div class="n-c-itemr" data-v-5bbd21ad>
                    <a
                      href="/news/detail/52"
                      style="display: inline-block"
                      data-v-5bbd21ad
                      ><img
                        src="../..//assets/picture/1624257817216_51529.png"
                        alt=""
                        data-v-5bbd21ad
                    /></a>
                  </div>
                </div>
                <div class="n-c-item" data-v-5bbd21ad>
                  <div class="n-c-iteml" data-v-5bbd21ad>
                    <h1 data-v-5bbd21ad>
                      警惕！员工打卡作弊，劳务公司出现亏损该怎么办？
                    </h1>
                    <p class="default-desc" data-v-5bbd21ad>
                      近期，有劳务公司反馈称，在考勤定位时员工可能存在作弊行为，导致公司出现资金损失。
                    </p>
                    <div class="mobile-content" data-v-5bbd21ad>
                      <p data-v-5bbd21ad>
                        近期，有劳务公司反馈称，在考勤定位时员工可能存在作弊行为，导致公司出现资金损失。
                      </p>
                      <img
                        src="../..//assets/picture/1621841034924_35358.jpg"
                        alt
                        data-v-5bbd21ad
                      />
                    </div>
                    <div class="news-time" data-v-5bbd21ad>2021-05-21</div>
                  </div>
                  <div class="n-c-itemr" data-v-5bbd21ad>
                    <a
                      href="/news/detail/51"
                      style="display: inline-block"
                      data-v-5bbd21ad
                      ><img
                        src="../..//assets/picture/1621841034924_35358.jpg"
                        alt=""
                        data-v-5bbd21ad
                    /></a>
                  </div>
                </div>
                <div class="n-c-item" data-v-5bbd21ad>
                  <div class="n-c-iteml" data-v-5bbd21ad>
                    <h1 data-v-5bbd21ad>广州制衣村月薪过万为何难招人？</h1>
                    <p class="default-desc" data-v-5bbd21ad>
                      广州制衣村位于广州市海珠区的康乐村，在狭窄的城中村小路上，上千名制衣厂的老板们拿着样衣和招工牌，排起近一公里长队，等着工人们光顾。像这样工人挑老板的招工场景正在广州多个制衣村上演。
                    </p>
                    <div class="mobile-content" data-v-5bbd21ad>
                      <p data-v-5bbd21ad>
                        广州制衣村位于广州市海珠区的康乐村，在狭窄的城中村小路上，上千名制衣厂的老板们拿着样衣和招工牌，排起近一公里长队，等着工人们光顾。像这样工人挑老板的招工场景正在广州多个制衣村上演。
                      </p>
                      <img
                        src="../..//assets/picture/1621838897540_78498.jpg"
                        alt
                        data-v-5bbd21ad
                      />
                    </div>
                    <div class="news-time" data-v-5bbd21ad>2021-05-21</div>
                  </div>
                  <div class="n-c-itemr" data-v-5bbd21ad>
                    <a
                      href="/news/detail/50"
                      style="display: inline-block"
                      data-v-5bbd21ad
                      ><img
                        src="../..//assets/picture/1621838897540_78498.jpg"
                        alt=""
                        data-v-5bbd21ad
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="full-screen" style="display: none">
            <div class="full-s-wrap">
              <div class="f-s-dialog">
                <div class="f-s-d-top">
                  <p>想了解更多，欢迎拨打电话</p>
                  <h3>400-166-1016</h3>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAB5klEQVRYR+3WTy8DQRgG8OfVHRHadIX6ED04dJ2Fq/bgIDj4Bg4k4uKCg0gkEuILOKibOHET5b4OfAknHX8jnSYjpdKt3Z2dmTbpRa87nefXd+Z9u4Qef6jH+fgHhCuQ8UaZI3cI4LUqbQP+R0fHlMunHTFwTKBpAFuC+0fB/UIA5noHAFaai64FR8kakcunmRi8AORkc78nwf0RJcDJFtaJaC+wqCI4isaIcDgk5Fmd380pAcCUw4Zfy5CYt0eMDzG3/zLwywHQreC1GeD+PQHQeNwJQj+8kaRoQxuEWXgCQFUJUfpbSsA8XAOgi7AL1wQkIQDdCxc1TwxGceNOvJ1AyoVgdwDUp3Pb44aZAeC3EiFEYO/oVlNNUkPA91YplvVOQW1zIrbPk8a4BSDqwn3HVASP6g41wRAQG95MkTeC14vhFo1HGACiWw1SPrYfhxlCE6Ds80+WnSiDgt2hj9AAaA2ZlC0iAaAV/nvAVggFwCjcGhEDsAoPIAonIFps3f34OxEB6ChcgdB8IXFc75yA2ZbefLw2v5ti2fZKEGG3VvU3lG9EzPVeAGR+FlmHx1XiUHB/VQlwXG+NgE0JXNW5WDKZajHzLsVcb5mkHKtB7OP5oaoEJP15dPu5xiDqdmT7fv+AL0ccUDCxc5LIAAAAAElFTkSuQmCC"
                    alt
                    class="popcloseBtn"
                  />
                </div>
                <div class="f-s-d-bottom">
                  <p>您也可以留下联系方式或扫码添加客服微信</p>
                  <p>我们会主动和您联系</p>
                  <div>
                    <img
                      src="../..//assets/picture/2d04568.png"
                      alt
                      class="contactwx"
                    />
                  </div>
                  <a href="/freeuse" target=""
                    ><img
                      src="../..//assets/picture/c8ce68b.png"
                      alt=""
                      class="contactbutton"
                  /></a>
                </div>
              </div>
            </div>
          </div>
          <div class="slidebar-p" style="display: none">
            <div class="pc-slider">
              <div class="slide-top">
                <div class="server-head">
                  <img src="../..//assets/picture/542c7ab.png" alt />
                </div>
                <p>全国免费客服电话</p>
                <div class="m-p-number"><a>400-166-1016</a></div>
                <a class="concatus-btn pc-btn">联系我们</a>
                <a href="tel:400-166-1016" class="concatus-btn moblie-btn"
                  >联系我们</a
                >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABIElEQVRYR+2VzU0DMRCFv3eHCEEXSChtpIIckJBogG4oAA4pIZXkFKWFKBG5chhktCsRa3c9tlFy8R7Xmnmf3/xYXPnTlfVpAM2B5kDSATObA0/AWtLRM7Zmdg88A0dJq6kYD8ASeAS+gI8URCf+CsyAvaT3WoBbICR8SEFE4qcO+FAFEILNLIb4lHSWuEQ85E6WoKePIM5uVyqeBTDgxC9EB9jX3GX735K4HZhwIhyFhssWz3ZgBCL8LhKvAQhz3tt+WYCBhvvuRrTIhaweGOp2IAD0eyIbwg0wNWpTI5pa3S4Az5yXQiQBPOKeZTXmhAfgLafJIid2//EavgB3wCre/2O3MrMbYAFsJW2qH6NUI9WcJ0tQk9wT2wCaA82BH9cHniEaBm9KAAAAAElFTkSuQmCC"
                  alt
                  class="closebtn"
                />
              </div>
              <div class="slide-wechat">
                <img src="../..//assets/picture/2ad8d88.png" alt />
                <p>扫码添加客服微信</p>
              </div>
              <a class="gotop">回到顶部</a>
            </div>
            <div class="moblie-slider">
              <a href="tel:400-166-1016"
                ><img
                  src="../..//assets/picture/4907a8b.png"
                  alt
                  class="moblie-s-top"
              /></a>
              <img
                src="../..//assets/picture/aaa2c8e.png"
                alt
                class="moblie-s-bottom"
              />
            </div>
          </div>
          <div class="footer-img">
                 <div class="pdt-20">
                    <a href="https://beian.miit.gov.cn/" target="blank">【工信部ICP备案】</a>
                    <a href="https://beian.miit.gov.cn/" target="blank">渝ICP备2021012534号</a>
                  </div>
                </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCom from "../../components/header-com.vue"
export default {
  props: [],
  components: {
    headerCom
  },
  data() {
    return {
      isScroll: false,
      isactive:3
    };
  },
  mounted() {
    window.addEventListener('scroll', this.setHeadPosition, false)
  },
  methods: {
    setHeadPosition() {
      let scrollTop = document.documentElement.scrollTop;
      this.isScroll= scrollTop>5 ? true : false
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.setHeadPosition, true);
  },
};
</script>

<style lang="scss" scoped>
input {
  background: none;
  outline: none;
  border: 1px solid #ccc;
}
input:focus {
  border: none;
}

.ls-animation > * {
  transform: perspective(400px) rotateX(90deg);
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  opacity: 0;
}
.ls-animationed > * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}
.ls-animationed > * :nth-of-type(5n + 1) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.ls-animationed > * :nth-of-type(5n + 2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.ls-animationed > * :nth-of-type(5n + 3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.ls-animationed > * :nth-of-type(5n + 4) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.ls-animationed > * :nth-of-type(5n) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
@-webkit-keyframes fadeInUp2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@-webkit-keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotateX(-5deg);
  }
  to {
    transform: perspective(400px);
    opacity: 1;
  }
}
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotateX(-5deg);
  }
  to {
    transform: perspective(400px);
    opacity: 1;
  }
}
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}
.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
}
.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: 50%;
  background-repeat: no-repeat;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
  right: 10px;
  left: auto;
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23fff'/%3E%3C/svg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23fff'/%3E%3C/svg%3E");
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z'/%3E%3C/svg%3E");
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z'/%3E%3C/svg%3E");
}
.swiper-button-lock {
  display: none;
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: opacity 0.3s;
  -o-transition: 0.3s opacity;
  transition: opacity 0.3s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}
.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}
.swiper-container-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
}
.swiper-container-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: top 0.2s, -webkit-transform 0.2s;
  transition: top 0.2s, -webkit-transform 0.2s;
  -o-transition: 0.2s transform, 0.2s top;
  transition: transform 0.2s, top 0.2s;
  transition: transform 0.2s, top 0.2s, -webkit-transform 0.2s;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  -webkit-transition: left 0.2s, -webkit-transform 0.2s;
  transition: left 0.2s, -webkit-transform 0.2s;
  -o-transition: 0.2s transform, 0.2s left;
  transition: transform 0.2s, left 0.2s;
  transition: transform 0.2s, left 0.2s, -webkit-transform 0.2s;
}
.swiper-container-horizontal.swiper-container-rtl
  > .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  -webkit-transition: right 0.2s, -webkit-transform 0.2s;
  transition: right 0.2s, -webkit-transform 0.2s;
  -o-transition: 0.2s transform, 0.2s right;
  transition: transform 0.2s, right 0.2s;
  transition: transform 0.2s, right 0.2s, -webkit-transform 0.2s;
}
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}
.swiper-container-rtl
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-horizontal
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}
.swiper-pagination-progressbar.swiper-pagination-white {
  background: hsla(0, 0%, 100%, 0.25);
}
.swiper-pagination-progressbar.swiper-pagination-white
  .swiper-pagination-progressbar-fill {
  background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}
.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25);
}
.swiper-pagination-progressbar.swiper-pagination-black
  .swiper-pagination-progressbar-fill {
  background: #000;
}
.swiper-pagination-lock {
  display: none;
}
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
.swiper-scrollbar-lock {
  display: none;
}
.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.swiper-slide-zoomed {
  cursor: move;
}
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12) infinite;
  animation: swiper-preloader-spin 1s steps(12) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%236c6c6c' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%23fff' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes swiper-preloader-spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube {
  overflow: visible;
}
.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}
.swiper-container-flip {
  overflow: visible;
}
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px;
}
.nuxt-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 0;
  opacity: 1;
  transition: width 0.1s, opacity 0.4s;
  background-color: #fff;
  z-index: 999999;
}
.nuxt-progress.nuxt-progress-notransition {
  transition: none;
}
.nuxt-progress-failed {
  background-color: red;
}
.full-wrap {
  width: 100%;
  height: 100%;
  position: relative;
}
.content {
  max-width: 900px;
  margin: 0 auto;
  line-height: 2;
  padding: 66px 5% 0;
}
@media screen and (max-width: 600px) {
  .news_header_title {
    font-size: 22px !important;
  }
}
.content img {
  max-width: 100%;
  text-align: center;
  margin: 10px auto;
}
.content-nav {
  font-size: 12px;
  margin: 30px 0;
  color: #888;
}
.content-nav a {
  text-decoration: none;
  color: #888;
}
.news_header_top .news_header_title {
  font-size: 28px;
  line-height: 1.5;
  color: #333;
  font-weight: 500;
}
.news_header_top .news_header_date {
  font-size: 12px;
  color: #888;
  margin-top: 10px;
  margin-bottom: 20px;
}
.header[data-v-1c1fd050] {
  height: 70px;
  position: fixed;
  z-index: 999;
  width: 100%;
}
.head[data-v-1c1fd050] {
  width: 92%;
  margin: 0 auto;
  position: relative;
}
.logo[data-v-1c1fd050] {
  float: left;
  width: 152px;
}
.logo[data-v-1c1fd050],
.logo-wrap[data-v-1c1fd050] {
  height: 70px;
  line-height: 70px;
}
.logo-wrap[data-v-1c1fd050],
.logo-wrap img[data-v-1c1fd050] {
  display: inline-block;
}
.logo-wrap img[data-v-1c1fd050] {
  height: 28px;
  width: 152px;
  margin-top: 21px;
}
.logo-wrap .default-logo[data-v-1c1fd050] {
  display: inline-block;
}
.logo-wrap .activ-logo[data-v-1c1fd050] {
  display: none;
}
.nav[data-v-1c1fd050] {
  float: right;
  text-align: center;
  position: relative;
}
.nav .nav-wrap[data-v-1c1fd050] {
  line-height: 70px;
}
.nav .nav-wrap > li:hover .pssubnav[data-v-1c1fd050] {
  display: block;
}
.pssubnav[data-v-1c1fd050] {
  position: absolute;
  top: 55px;
  left: -16px;
  width: 114px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 0;
  z-index: 99999;
  display: none;
  overflow: hidden;
  box-shadow: 0 0 5px 0 #dadfe4;
}
.pssubnav li[data-v-1c1fd050] {
  overflow: hidden;
  font-size: 0;
  float: left;
}
.pssubnav li[data-v-1c1fd050],
.pssubnav li a[data-v-1c1fd050] {
  width: 100%;
  line-height: 33px;
  height: 33px;
}
.pssubnav li a[data-v-1c1fd050] {
  color: #333;
  text-align: center;
  font-size: 13px;
  display: inline-block;
}
.pssubnav li a[data-v-1c1fd050]:active,
.pssubnav li a[data-v-1c1fd050]:hover {
  color: #2b69fb;
  background-color: #f0f0f0;
}
.header-right[data-v-1c1fd050] {
  float: right;
  width: auto;
  height: 70px;
  line-height: 70px;
  margin-left: 60px;
}
.header-right a[data-v-1c1fd050] {
  margin: 0 8px;
  text-align: center;
}
.home-login[data-v-1c1fd050] {
  display: inline-block;
  width: 56px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
}
.home-login[data-v-1c1fd050]:hover {
  background: #fff;
  color: #2b69fb;
  font-weight: 400;
}
.free-use[data-v-1c1fd050] {
  display: inline-block;
  width: auto;
  padding: 0 16px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
}
.free-use[data-v-1c1fd050]:hover {
  background: #fff;
  color: #2b69fb;
  font-weight: 400;
}
.nav-wrap > li[data-v-1c1fd050] {
  margin: 0 25px;
  line-height: 70px;
  position: relative;
}
.nav-wrap a[data-v-1c1fd050] {
  height: 55px;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  display: inline-block;
}
.product-server .down-arrow[data-v-1c1fd050] {
  display: inline-block;
  height: 10px;
  width: 10px;
  vertical-align: middle;
}
.nav-wrap a.active[data-v-1c1fd050],
.nav-wrap a[data-v-1c1fd050]:hover {
  color: #fff;
  font-weight: 600;
  border-bottom: 3px solid #fff;
}
.product-server a.active[data-v-1c1fd050],
.product-server a[data-v-1c1fd050]:hover {
  color: #fff;
  font-weight: 600;
  border-bottom: none;
}
.mobile-menu[data-v-1c1fd050] {
  display: none;
  float: right;
  height: 80px;
  line-height: 80px;
}
.mobile-menu img[data-v-1c1fd050] {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: middle;
}
.show-active[data-v-1c1fd050] {
  display: inline-block !important;
}
.hide-active[data-v-1c1fd050] {
  display: none !important;
}
.active-nav[data-v-1c1fd050] {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 3px 0 #f1f5f9;
}
.active-nav .show-active[data-v-1c1fd050] {
  display: none !important;
}
.active-nav .hide-active[data-v-1c1fd050] {
  display: inline-block !important;
}
.active-nav .nav-wrap a[data-v-1c1fd050] {
  height: 55px;
  text-decoration: none;
  color: #333;
  display: inline-block;
}
.active-nav .nav-wrap a.active[data-v-1c1fd050],
.active-nav .nav-wrap a[data-v-1c1fd050]:hover {
  color: #2b69fb;
  font-weight: 600;
  border-bottom: 3px solid #2b69fb;
}
.active-nav .header-right[data-v-1c1fd050] {
  float: right;
  width: auto;
  height: 70px;
  line-height: 70px;
}
.active-nav .header-right a[data-v-1c1fd050] {
  margin: 0 8px;
  text-align: center;
}
.active-nav .product-server a.active[data-v-1c1fd050],
.active-nav .product-server a[data-v-1c1fd050]:hover {
  color: #2b69fb;
  font-weight: 600;
  border-bottom: none;
}
.active-nav .home-login[data-v-1c1fd050] {
  display: inline-block;
  width: 56px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #2b69fb;
  color: #2b69fb;
}
.active-nav .home-login[data-v-1c1fd050]:hover {
  background: #2b69fb;
  color: #fff;
  font-weight: 400;
}
.active-nav .free-use[data-v-1c1fd050] {
  display: inline-block;
  width: auto;
  padding: 0 16px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #2b69fb;
  color: #2b69fb;
}
.active-nav .free-use[data-v-1c1fd050]:hover {
  background: #2b69fb;
  color: #fff;
  font-weight: 400;
}
@media screen and (max-width: 1050px) {
  .nav-wrap > li[data-v-1c1fd050] {
    margin: 0 11px;
  }
  .header-right[data-v-1c1fd050] {
    margin-left: 40px;
  }
}
@media screen and (max-width: 900px) {
  .nav-wrap > li[data-v-1c1fd050] {
    margin: 0 10px;
  }
  .header-right[data-v-1c1fd050] {
    margin-left: 20px;
  }
}
@media screen and (max-width: 850px) {
  .header[data-v-1c1fd050] {
    height: 70px;
    position: fixed;
    z-index: 999;
    width: 100%;
  }
  .head[data-v-1c1fd050] {
    width: 92%;
    margin: 0 auto;
    position: relative;
  }
  .logo[data-v-1c1fd050] {
    float: left;
    overflow: hidden;
  }
  .nav[data-v-1c1fd050] {
    float: right;
    text-align: center;
    position: relative;
    display: none;
  }
  .nav .nav-wrap[data-v-1c1fd050] {
    line-height: 80px;
  }
  .mobile-menu[data-v-1c1fd050] {
    display: block;
    height: 80px;
    line-height: 70px;
  }
  .mobile-menu img[data-v-1c1fd050] {
    width: 25px;
    height: 20px;
  }
  .header-right[data-v-1c1fd050] {
    display: none;
  }
}
.slidebar-wrap {
  background: rgba(0, 0, 0, 0.3);
}
.slidebar-wrap,
.slidebar-wrap .slidebar-content {
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  height: 100%;
  z-index: 999999;
}
.slidebar-wrap .slidebar-content {
  background: #fff;
  transition: width 0.5s;
}
.slidebar-wrap-active {
  width: 100%;
}
.slidebar-wrap-active .slidebar-content {
  transition: width 0.5s;
  position: fixed;
  right: 0;
  top: 0;
  width: 64%;
  height: 100%;
  background: #fff;
  z-index: 999999;
}
.slidebar-nav {
  width: 100%;
  padding: 12px 0;
}
.slidebar-nav li {
  text-align: left;
}
.slidebar-nav li,
.slidebar-nav li a {
  width: 100%;
  color: #051033;
  font-size: 16px;
}
.slidebar-nav li a {
  padding: 16px 24px;
  display: inline-block;
}
.slidebar-nav li .activeColor,
.slidebar-nav li a:active,
.slidebar-nav li a:hover {
  color: #2b69fb;
}
.slidebar-nav .p-s-default {
  display: inline-block;
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABJElEQVRIS+3UMU7DMBTG8b+dhnYpqoCRuQxICFTEwBkQQw/Awk16HwYWJpBYEUiIhQuwoxYESBD8kBGRkppguymdkjGx/cv77GfFgh+1YI8GnHviTaT/G+lSbzAycIyYo2xye1FL6w7W0kSdgrQ/xm/7cP9u1yvtYau3e6KQIfAqog+zyfVs6DeGnbsl8Jzp1jqPV08OSHdvNU0+z4HtmdECBrwI6iAb31zmabmntA7qwdwK899Y3lxJdcdGEl5pAFYN2i8xaCD2NxiKRmB+0IdGYmFgFWrMXX70fzuNVT0cfpdO7SnCA4qNGCy8wvLptX268/PK6TPf7RReYRFNOmci0gc9LDa1D4uvsLyiBkwIUhwTX2GsMDW+AWsG6E5vIp17pF+hPaQdeBF4oAAAAABJRU5ErkJggg==)
    no-repeat 100%;
  background-size: 16px 16px;
  background-position: 92% 13px;
}
.slidebar-nav .p-s-active {
  display: inline-block;
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABFklEQVRIS+3WsUoDMRzH8e8/nIIWtbj7Ai4iVFyFc3Jx8Xl8Exd3O0ifoVtVBAedHUWKLqfpJVLxqD3vrvePR6fLnOTD/5d/QoQlD1myRws2nngbaVmkBnAheasjjbq9I3B9EXmyaXLC28OrBlaBU0zwA6Dzg9xalxxr0NrgHOZ5RNgB1gEVWgvMVXZvU+LImD0Rd61FF4JFGO+jl2mk0dZBrEUrwSosa5QcemddEledaSlYBwtBC0ENpkX/gCGYBp0D/4PVRWfg9uFm5CbPAhvAd+tn3ah5SfLd6/FXk/HNWbbHrwp3V1e6a0OQD5v601BsVul+jJhLAxef49F5AaitI2z+wosftm35qhZsOtH2m9h4onwBYpGwHeK+154AAAAASUVORK5CYII=)
    no-repeat 100%;
  background-size: 16px 16px;
  background-position: 92% 16px;
}
.slidebar-nav .s-b-sub-nav {
  height: 0;
}
.slidebar-nav .s-b-sub-nav,
.slidebar-nav .s-b-sub-nav-active {
  width: 100%;
  overflow: hidden;
  text-align: center;
  background: #f7fafd;
}
.slidebar-nav .s-b-sub-nav-active {
  height: 100%;
  margin-top: 20px;
  transition: height 0.5s;
}
.n-content[data-v-5bbd21ad] {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.n-c-content[data-v-5bbd21ad] {
  width: 100%;
  height: auto;
  padding-top: 73px;
}
.n-c-wrap[data-v-5bbd21ad] {
  max-width: 1000px;
  margin: 0 auto;
  height: auto;
}
.n-c-wrap .n-c-item[data-v-5bbd21ad] {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-bottom: 1px solid #efefef;
  padding: 30px;
  cursor: pointer;
}
.n-c-wrap .n-c-item[data-v-5bbd21ad]:hover {
  background: #f7fafd;
  border: none;
}
.n-c-wrap .n-c-item[data-v-5bbd21ad] {
  display: flex;
  justify-content: space-between;
}
.n-c-wrap .n-c-iteml[data-v-5bbd21ad] {
  flex: 1;
  text-align: left;
}
.n-c-wrap .n-c-iteml h1[data-v-5bbd21ad] {
  font-size: 20px;
  font-weight: 500;
  color: #051033;
  line-height: 28px;
}
.n-c-wrap .n-c-iteml p[data-v-5bbd21ad] {
  font-size: 16px;
  font-weight: 400;
  color: #697085;
  line-height: 24px;
  margin: 10px 0 18px;
}
.n-c-wrap .n-c-iteml .news-time[data-v-5bbd21ad] {
  font-size: 12px;
  font-weight: 400;
  color: #a5a9b6;
  line-height: 12px;
}
.n-c-wrap .n-c-iteml .mobile-content[data-v-5bbd21ad] {
  display: none;
}
.n-c-wrap .n-c-itemr[data-v-5bbd21ad] {
  margin-left: 62px;
}
.n-c-wrap .n-c-itemr img[data-v-5bbd21ad] {
  display: inline-block;
  width: 250px;
  height: auto;
  max-height: 140px;
}
@media screen and (max-width: 1000px) {
  .n-content[data-v-5bbd21ad] {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 0;
  }
  .n-c-content[data-v-5bbd21ad] {
    width: 100%;
    height: auto;
    overflow: scroll;
    padding-top: 36px;
  }
  .n-c-wrap[data-v-5bbd21ad] {
    width: 92%;
    margin: 0 auto;
    height: auto;
    overflow-y: scroll;
  }
  .n-c-wrap .n-c-item[data-v-5bbd21ad] {
    height: auto;
    overflow: hidden;
    border-bottom: 1px solid #d8d8d8;
  }
  .n-c-wrap .n-c-item[data-v-5bbd21ad]:hover {
    background: none;
    box-shadow: none;
    border-bottom: none;
  }
  .n-c-wrap .n-c-item[data-v-5bbd21ad] {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .n-c-wrap .n-c-iteml[data-v-5bbd21ad] {
    flex: 1;
    text-align: left;
  }
  .n-c-wrap .n-c-iteml h1[data-v-5bbd21ad] {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #051033;
    line-height: 22px;
  }
  .n-c-wrap .n-c-iteml .default-desc[data-v-5bbd21ad] {
    font-size: 16px;
    font-weight: 400;
    color: #697085;
    line-height: 24px;
    margin: 10px 0 18px;
    display: none;
  }
  .n-c-wrap .n-c-iteml .mobile-content[data-v-5bbd21ad] {
    width: 100%;
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .n-c-wrap .n-c-iteml .mobile-content p[data-v-5bbd21ad] {
    flex: 1;
    min-width: 0;
    height: 62px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 20px;
    font-size: 14px;
  }
  .n-c-wrap .n-c-iteml .mobile-content img[data-v-5bbd21ad] {
    display: inline-block;
    width: 109px;
    height: 61px;
    margin-left: 8px;
  }
  .n-c-wrap .n-c-iteml .news-time[data-v-5bbd21ad] {
    font-size: 12px;
    font-weight: 400;
    color: #a5a9b6;
    line-height: 12px;
  }
  .n-c-wrap .n-c-itemr[data-v-5bbd21ad] {
    margin-left: 62px;
    display: none;
  }
  .n-c-wrap .n-c-itemr img[data-v-5bbd21ad] {
    display: inline-block;
    width: 250px;
    height: auto;
  }
}
.news-banner-box {
  // width: 100%;
  // height: 571px;
  // position: relative;
  // background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/f0c5ab1.png)
  //   no-repeat;
  // background-size: 100% 100%;
}
.news-banner-box .banner-desc {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 50%;
  transform: translateY(-25%);
  z-index: 8;
}
.news-banner-box .banner-desc .banner-content {
  max-width: 1000px;
  height: auto;
  margin: 0 auto;
}
.news-banner-box .banner-desc .banner-content .banner-content-title {
  font-size: 36px;
  color: #fff;
  font-weight: 600;
  line-height: 72px;
}
.news-banner-box .banner-desc .banner-content .banner-content-subtitle {
  font-size: 14px;
  color: hsla(0, 0%, 100%, 0.8);
  font-weight: 400;
  line-height: 38px;
  margin-top: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 114px;
}
.news-banner-box #parent {
  // height: 571px;
  // width: 100%;
  // background: url("../../assets/images/header_top.png") no-repeat;
  // background-size: cover;
}
.news-banner-box #parent .swiper-container {
  visibility: hidden;
  z-index: -1;
  position: absolute;
}
.news-banner-box #parent:hover .swiper-container {
  visibility: visible;
}
.news-banner-box #parent .swiper-slide {
  width: 100%;
  height: 571px;
}
.news-banner-box #parent .swiper-slide .swiper-silde-item {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.news-banner-box #parent .swiper-slide .home-banner {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.news-banner-box #parent .swiper-slide .swipercontent {
  width: 1000px;
  height: 571px;
  color: transparent;
  background: none;
}
.news-banner-box #parent .swiper-pagination-wrap {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
}
.news-banner-box #parent .swiper-pagination-inner {
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.news-banner-box #parent .swiper-pagination {
  position: absolute;
  bottom: 128px;
  left: 0;
  border-radius: none;
  text-align: left;
}
.news-banner-box #parent .swiper-pagination-bullet {
  display: inline-block;
  width: 32px;
  height: 3px;
  border-radius: 0;
  margin-left: 15px;
}
.news-banner-box #parent .swiper-pagination-bullet-active {
  display: inline-block;
  width: 32px;
  height: 3px;
  background: #fff;
  border-radius: 0;
}
.news-banner-box .home-sub-nav {
  width: 100%;
  height: 92px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
}
.news-banner-box .home-sub-nav .home-sub-nav-wrap {
  max-width: 1000px;
  height: 92px;
  margin: 0 auto;
}
.news-banner-box .home-sub-nav .home-sub-nav-wrap ul {
  width: 100%;
  line-height: 92px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 127px;
}
.news-banner-box .home-sub-nav .home-sub-nav-wrap ul li {
  display: inline-block;
  line-height: 92px;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  text-align: center;
}
.news-banner-box .home-sub-nav .home-sub-nav-wrap ul li img {
  margin-right: 8px;
}
.news-banner-box .home-sub-nav .home-sub-nav-wrap ul li .zhifubaoicon {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
}
.news-banner-box .home-sub-nav .home-sub-nav-wrap ul .lined {
  height: 20px;
  width: 2px;
  background: #fff;
}
@media screen and (max-width: 1000px) {
  .news-banner-box {
    width: 100%;
    height: 246px;
    position: relative;
    text-align: center;
    background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/f0c5ab1.png)
      no-repeat;
    background-size: 100% 100%;
  }
  .news-banner-box .banner-desc {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 8;
  }
  .news-banner-box .banner-desc .banner-content {
    width: 92%;
    height: 100%;
    margin: 0 auto;
    text-align: left;
  }
  .news-banner-box .banner-desc .banner-content .banner-content-title {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    line-height: 20px;
  }
  .news-banner-box .banner-desc .banner-content .banner-content-subtitle {
    font-size: 14px;
    color: hsla(0, 0%, 100%, 0.8);
    font-weight: 400;
    line-height: 24px;
    margin-top: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    max-height: 96px;
  }
  .news-banner-box #parent {
    height: 246px;
    width: 100%;
  }
  .news-banner-box #parent .swiper-container {
    visibility: hidden;
    z-index: -1;
    position: absolute;
  }
  .news-banner-box #parent:hover .swiper-container {
    visibility: visible;
  }
  .news-banner-box #parent .swiper-slide {
    width: 100%;
    height: 246px;
  }
  .news-banner-box #parent .swiper-slide Î .swiper-silde-item {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .news-banner-box #parent .swiper-slide .home-banner {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .news-banner-box #parent .swiper-slide .swipercontent {
    width: 1000px;
    height: 246px;
    color: transparent;
    background: none;
  }
  .news-banner-box #parent .swiper-pagination-wrap {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
  }
  .news-banner-box #parent .swiper-pagination-inner {
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
  .news-banner-box #parent .swiper-pagination {
    position: absolute;
    bottom: 30px;
    left: 0;
    border-radius: none;
    text-align: left;
  }
  .news-banner-box #parent .swiper-pagination-bullet,
  .news-banner-box #parent .swiper-pagination-bullet-active {
    display: inline-block;
    width: 32px;
    height: 3px;
    border-radius: none;
  }
  .news-banner-box #parent .swiper-pagination-bullet-active {
    background: #fff;
  }
  .news-banner-box .home-sub-nav {
    width: 100%;
    height: 92px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.3);
  }
  .news-banner-box .home-sub-nav .home-sub-nav-wrap {
    max-width: 1000px;
    height: 92px;
    margin: 0 auto;
  }
  .news-banner-box .home-sub-nav .home-sub-nav-wrap ul {
    width: 100%;
    line-height: 92px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 127px;
  }
  .news-banner-box .home-sub-nav .home-sub-nav-wrap ul li {
    display: inline-block;
    line-height: 92px;
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    text-align: center;
  }
  .news-banner-box .home-sub-nav .home-sub-nav-wrap ul li img {
    margin-right: 8px;
  }
  .news-banner-box .home-sub-nav .home-sub-nav-wrap ul li .zhifubaoicon {
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
  }
  .news-banner-box .home-sub-nav .home-sub-nav-wrap ul .lined {
    height: 20px;
    width: 2px;
    background: #fff;
  }
}
.full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
}
.full-screen .full-s-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.full-screen .f-s-dialog {
  width: 493px;
  height: 533px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 24px 0 rgba(31, 31, 31, 0.5);
  border-radius: 10px;
  background: #fff;
  z-index: 999;
  transform: translate(-50%, -50%);
}
.full-screen .f-s-dialog .f-s-d-top {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 163px;
  background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/e062776.png)
    no-repeat;
  background-size: 100% 100%;
}
.full-screen .f-s-dialog .f-s-d-top p {
  font-size: 16px;
  font-weight: 400;
  color: #051033;
  line-height: 22px;
}
.full-screen .f-s-dialog .f-s-d-top h3 {
  font-size: 32px;
  font-weight: 600;
  color: #051033;
  line-height: 45px;
}
.full-screen .f-s-dialog .f-s-d-top .popcloseBtn {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.f-s-d-bottom {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.f-s-d-bottom p {
  font-size: 16px;
  font-weight: 400;
  color: #051033;
  line-height: 22px;
}
.f-s-d-bottom p:first-child {
  margin-top: 24px;
}
.f-s-d-bottom .contactwx {
  display: inline-block;
  height: 218px;
  width: 218px;
}
.f-s-d-bottom .contactbutton {
  display: inline-block;
  height: 38px;
  width: auto;
  cursor: pointer;
}
.slidebar-p {
  position: fixed;
  right: 20px;
  bottom: 120px;
  width: 156px;
  height: auto;
  overflow: hidden;
  z-index: 9999;
  text-align: center;
  background: none;
}
.slide-top {
  width: 100%;
  height: 217px;
  background-size: 100% 100%;
  position: relative;
}
.slide-top p {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 17px;
  margin-top: 16px;
}
.server-head {
  text-align: center;
}
.server-head img {
  display: inline-block;
  width: 72px;
  height: auto;
  margin-top: 24px;
}
.m-p-number a {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.concatus-btn,
.m-p-number a {
  display: inline-block;
  color: #fff;
}
.concatus-btn {
  text-align: center;
  width: 108px;
  height: 32px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  border: 1px solid #fff;
  margin-top: 16px;
}
.moblie-btn {
  display: none;
}
.closebtn {
  display: inline-block;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.slide-wechat {
  width: 100%;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 40px 0 #f1f5f9;
  border-radius: 10px;
  padding: 10px 0 8px;
  margin: 8px 0;
}
.slide-wechat img {
  display: inline-block;
  width: 96px;
  height: 96px;
}
.slide-wechat p {
  font-size: 12px;
  color: #051033;
  line-height: 17px;
  margin: 8px;
}
.gotop,
.slide-wechat p {
  font-weight: 400;
  text-align: center;
}
.gotop {
  display: inline-block;
  line-height: 45px;
  width: 156px;
  height: 45px;
  background: #55a1fd;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
}
.moblie-slider {
  display: none;
}
@media screen and (max-width: 1000px) {
  .slidebar-p {
    position: fixed;
    right: 0;
    bottom: 10px;
    width: 30px;
    height: auto;
    overflow: scroll;
    z-index: 9999;
    text-align: center;
    background: red;
  }
  .pc-slider {
    display: none;
  }
  .moblie-slider {
    display: block;
    position: fixed;
    right: 0;
    top: 50%;
    width: 74px;
    overflow: hidden;
    z-index: 9999;
    text-align: center;
    background: none;
    height: auto;
  }
  .moblie-slider img {
    display: inline-block;
    width: 74px;
    height: auto;
  }
  .moblie-slider img:nth-child(2) {
    transform: translateY(-17px);
  }
}
.footer[data-v-38cb5c44] {
  width: 100%;
  height: auto;
  background: #061845;
  padding: 40px 0;
}
.footer .footer-wrap[data-v-38cb5c44] {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}
.footer .footer-wrap .footer-wrap-top[data-v-38cb5c44] {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
}
.footer .footer-wrap .weixinNumber[data-v-38cb5c44] {
  display: inline-block;
  width: 144px;
  height: 144px;
  margin: 32px 0;
}
.footer .footer-wrap .footer-bottom[data-v-38cb5c44] {
  width: 51.8%;
  font-size: 16px;
  color: hsla(0, 0%, 100%, 0.6);
  line-height: 22px;
  margin: 0 auto 20px;
}
.footer .footer-wrap .footer-bottom .footer-left[data-v-38cb5c44] {
  float: left;
  overflow: hidden;
}
.footer .footer-wrap .footer-bottom .footer-right[data-v-38cb5c44] {
  float: right;
  overflow: hidden;
}
.footer .footer-wrap .footer-bottom .footer-right a[data-v-38cb5c44] {
  color: hsla(0, 0%, 100%, 0.6);
}
@media screen and (max-width: 1000px) {
  .footer[data-v-38cb5c44] {
    width: 100%;
    height: auto;
    background: #061845;
    padding: 40px 0;
  }
  .footer .footer-wrap[data-v-38cb5c44] {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }
  .footer .footer-wrap .footer-wrap-top[data-v-38cb5c44] {
    font-size: 16px;
    color: #fff;
    line-height: 22px;
  }
  .footer .footer-wrap .weixinNumber[data-v-38cb5c44] {
    display: inline-block;
    width: 144px;
    height: 144px;
    margin: 32px 0;
  }
  .footer .footer-wrap .footer-bottom[data-v-38cb5c44] {
    width: 92%;
    font-size: 12px;
    color: hsla(0, 0%, 100%, 0.6);
    line-height: 22px;
    margin: 0 auto 20px;
  }
  .footer .footer-wrap .footer-bottom .footer-left[data-v-38cb5c44],
  .footer .footer-wrap .footer-bottom .footer-right[data-v-38cb5c44] {
    width: 100%;
    text-align: center;
    overflow: hidden;
  }
}
</style>

